@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: url('static/fonts/Inter-Regular.ttf');
}
@font-face {
    font-family: 'Inter-Bold';
    src: url('static/fonts/Inter-Bold.ttf');
}
@font-face {
    font-family: 'Inter-Medium';
    src: url('static/fonts/Inter-Medium.ttf');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.font-inter {
    font-family: 'Inter';
}

.font-brand {
    font-weight: 700;
    letter-spacing: 0.025em;
    font-family: 'Inter';
}

.table-item {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 999px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.table-item-blue {
    color: #455472;
    border: 1px solid var(--Content-1, #455472);
    background: var(--Secondary, #eaf0fe);
}
.table-item-green {
    color: #02d217;
    border: 1px solid var(--Green, #02d217);
    background: #f0fff1;
}
.table-item-red {
    color: #e50000;
    border: 1px solid var(--Red, #e50000);
    background: #fff3f3;
}
.table-item-red{
  color: #E50000;
  border: 1px solid var(--Red, #E50000);
  background: #FFF3F3;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  background-color: #CED2DB !important
}

.react-datepicker-popper {
  z-index: 100 !important;
}
