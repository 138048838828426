.ant-table-row-selected > .ant-table-cell {
    background-color: #fafafa !important;
}
.ant-tabs .ant-tabs-tab {
    font-family: Inter-Bold;
    font-size: 20px;
}
.ant-btn {
    font-family: 'Inter-Medium';
    height: 52px;
    font-size: 16px;
    padding: 16px 40px;
}
.ant-upload {
    background-color: transparent !important;
}
.ant-select-single {
    height: 52px;
}
.ant-select-selector {
    border-radius: 16px !important;
}
.ant-tooltip-inner {
    color: black !important;
    background-color: white !important;
}
.ant-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.full-drawer .ant-drawer-body {
    padding: 0 !important;
}
.white-spin .ant-spin-dot i {
    background-color: #ffffff; /* Change to your desired color */
}

@media (min-width: 640px) {
    .ant-table-selection-column {
        width: 35px !important;
    }
}

@media (max-width: 639px) {
    .ant-table-selection-column {
        width: 40px !important;
    }
}
