.ant-menu-item {
    color: #455472 !important;
    padding: 8px 16px !important;
    margin-left: 0px !important;
}

.ant-menu-item.ant-menu-item-selected {
    color: #05004d !important;
    background-color: #eaf0fe;
    border-radius: 32px;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-active {
    background-color: #eaf0fe !important;
}

.ant-menu-item.ant-menu-item-active:not(.ant-menu-item-selected) {
    background-color: #fafafa !important;
    border-radius: 32px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
    width: 40px !important;
    height: 40px !important;
    border-radius: 9999px !important;
    padding: 0 !important;
}

.ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected {
    background-color: #eaf0fe !important;
}

.hamburger-box {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after,
.hamburger-inner-active,
.hamburger-inner-active::before,
.hamburger-inner-active::after {
    width: 20px;
    height: 3px;
    border-radius: 2px;
    position: absolute;
    display: block;
    background-color: #455472;
}

.white-hamburger,
.white-hamburger::before,
.white-hamburger::after {
    background-color: #455472 !important;
}

.hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner::before {
    content: '';
    transition:
        top 0.075s 0.12s ease,
        opacity 0.075s ease;
    top: -8px;
}

.hamburger-inner::after {
    content: '';
    transition:
        bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -8px;
}

.hamburger-inner-active {
    transition-duration: 0.075s;
    transform: rotate(45deg);
    transition-delay: 0.12s;
    top: 50%;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger-inner-active::before {
    content: '';
    top: 0;
    opacity: 0;
    transition:
        top 0.075s ease,
        opacity 0.075s 0.12s ease;
}

.hamburger-inner-active::after {
    content: '';
    bottom: 0;
    transform: rotate(-90deg);
    transition:
        bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
